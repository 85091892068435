<template>
    <div>
        <loading-state
            v-if="loading"
            title="Loading repair kits to pick"
        />

        <template v-if="!loading">
            <picking-filter
                :searchValue.sync="searchValue"
                :repairKitsToBePicked.sync="repairKitsToBePicked"
            />

            <search-banner
                v-if="selectionsMade"
                placeholder="Enter your picking zone or a kit ID"
                :filteredSearchValue.sync="filteredSearchValue"
                sticky
            />

            <div class="container">
                <template v-if="repairKitsToBePicked.length > 0 && displayFilteredKits">
                    <!-- To be picked kits -->
                    <div class="row mb-2">
                        <div class="col-md-12 d-flex">
                            <label class="mt-2">{{ toBePickedText }}</label>
                            <!--                        <div class="ml-auto mr-2 mt-3 cursor-pointer" v-if="!syncing" @click="syncList()">-->
                            <!--                            <icon-refresh size="sm" class="icon-all-white icon-chunky"></icon-refresh>-->
                            <!--                        </div>-->
                            <!--                        <div class="ml-auto mr-2 mt-3 cursor-not-allowed" v-if="syncing">-->
                            <!--                            <icon-refresh size="sm" class="icon-all-white icon-chunky fa-spin"></icon-refresh>-->
                            <!--                        </div>-->
                        </div>
                    </div>
                    <div class="card-grid vertical">
                        <div v-for="repairKit in kitToBePickedList" :key="repairKit.id">
                            <router-link
                                :to="{name: 'repairKitPicking.repairKitLabel', params: {repairKitId:repairKit.id}}"
                                class="card card-body card-link py-3 justify-content-between flex-row align-items-center">
                                <div>
                                    Repair kit: <strong>{{repairKit.id}}</strong><br>
                                    <small v-if="repairKit.jit_stock_order_id">
                                        JIT Stock Order Priority Repair
                                    </small>
                                    <small v-else>
                                        <i class="fal fa-info-circle"></i>Kit Type: {{ repairKit.kit_type_text }} <span v-if="repairKit.priority">- PRIORITY REPAIR</span>
                                    </small>
                                </div>
                                <span v-if="repairKit.currently_picking" class="badge badge-red badge-sm">Ready</span>
                                <div>Zone {{repairKit.picking_zone}}</div>
                            </router-link>
                        </div>
                      <div v-if="totalPages > 1" class="d-flex justify-content-between align-items-center mt-3 w-100">
                        <button class="btn btn-success" :style="{ visibility: currentPage > 1 ? 'visible' : 'hidden' }"  @click="previousPage">Previous</button>

                        <p class="mb-0">Page {{ currentPage }} / {{ totalPages }}</p>

                        <button class="btn btn-success" :style="{ visibility: currentPage < totalPages ? 'visible' : 'hidden' }"  @click="nextPage">Next</button>
                      </div>
                    </div>

                    <!-- In-progress kits -->
                    <div class="row mb-2">
                        <div class="col-md-12 d-flex">
                            <label class="mt-2">{{ inProgressText }}</label>
                            <!--                        <div class="ml-auto mr-2 mt-3 cursor-pointer" v-if="!syncing" @click="syncList()">-->
                            <!--                            <icon-refresh size="sm" class="icon-all-white icon-chunky"></icon-refresh>-->
                            <!--                        </div>-->
                            <!--                        <div class="ml-auto mr-2 mt-3 cursor-not-allowed" v-if="syncing">-->
                            <!--                            <icon-refresh size="sm" class="icon-all-white icon-chunky fa-spin"></icon-refresh>-->
                            <!--                        </div>-->
                        </div>
                    </div>
                    <div class="card-grid vertical">
                        <div v-for="repairKit in kitBeingPickedList" :key="repairKit.id">
                            <router-link
                                :to="{name: 'repairKitPicking.repairKitLabel', params: {repairKitId:repairKit.id}}"
                                class="card card-body card-link py-3 justify-content-between flex-row align-items-center">
                                <div>
                                    Repair kit: <strong>{{repairKit.id}}</strong><br>
                                    <small v-if="repairKit.jit_stock_order_id">
                                        JIT Stock Order Priority Repair
                                    </small>
                                    <small v-else>
                                        <i class="fal fa-info-circle"></i>Kit Type: {{ repairKit.kit_type_text }} <span v-if="repairKit.priority">- PRIORITY REPAIR</span>
                                    </small>
                                </div>
                                <span v-if="repairKit.currently_picking" class="badge badge-red badge-sm">Picking</span>
                                <div>Zone {{repairKit.picking_zone}}</div>
                            </router-link>
                        </div>
                    </div>

                  <div class="d-flex justify-content-center align-items-center mt-3 w-100">
                    <button class="btn btn-success" @click="loadMore" v-if="!allItemsLoaded">
                      Load More
                    </button>
                  </div>

                </template>

                <!-- Empty State -->
                <empty-state
                    v-if="repairKitsToBePicked.length < 1"
                    emptyIcon
                    icon="box"
                    title="There are no kits to pick"
                />

                <!-- No Search Results -->
                <empty-state
                    v-if="repairKitsToBePicked.length > 0 && !displayFilteredKits"
                    emptyIcon
                    faIcon="search"
                    :title="emptyStateText"
                    subtitle=""
                />
            </div>

        </template>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        data() {
            return {
                loading: true,
                searchValue: {},
                filteredSearchValue: "",
                syncing: false,
                currentPage: 1,
                pageSize: 10,
               itemsToLoad: 5,

            }
        },

        props:['kitPicked'],

        mounted() {
            this.loadTodaysRepairKits()
                .then(() => {
                    this.loading = false;
                })
                .catch(e => {
                    this.displayToast({text: e, type: 'error'});
                });

            if (this.kitPicked) {
                this.displayToast({text: 'Kit has been picked', type: 'success'});
            }
        },

      watch: {
        filteredRepairKits() {
          this.ensureCurrentPageInBounds();
        }
      },

        computed: {
            ...mapGetters([
                'repairKits',
                'repairKitsToBePicked'
            ]),

          filteredRepairKits() {
            return this.filteredRepairKitsToBePicked();
          },

          totalPages() {
            return Math.ceil(this.filteredRepairKitsToBePicked(false).length / this.pageSize);
          },
          kitToBePickedList() {
            const start = (this.currentPage - 1) * this.pageSize;
            const end = start + this.pageSize;
            return this.filteredRepairKitsToBePicked(false).slice(start, end);
          },
          kitBeingPickedList() {
            return this.filteredRepairKitsToBePicked(true).slice(0, this.itemsToLoad);
          },
          allItemsLoaded() {
            return this.itemsToLoad >= this.filteredRepairKitsToBePicked(true).length;
          },

            emptyStateText() {
                if (!this.selectionsMade) {
                    return "Select a repair kit type to view repair kits."
                }
                return "Enter a picking zone or kit ID to view repair kits";
            },

            displayFilteredKits() {
                if (!this.selectionsMade) return false;
                let sum = this.filteredRepairKitsToBePicked(false).length + this.filteredRepairKitsToBePicked(true).length;
                return (this.filteredSearchValue || (sum < 16));
            },

            searchValueIsAZone() {
                if (!this.filteredSearchValue) {
                    return true;
                }
                return isNaN(parseInt(this.filteredSearchValue, 10));
            },

            toBePickedText() {
                if (this.searchValueIsAZone) {
                    return `Kits to pick in this zone (${this.filteredRepairKitsToBePicked(false).length})`;
                }

                return `Kits with this ID (${this.filteredRepairKitsToBePicked(false).length})`;
            },

            inProgressText() {
                if (this.searchValueIsAZone) {
                    return `Kits being picked in this zone (${this.filteredRepairKitsToBePicked(true).length})`;
                }

                return `Kits with this ID being picked (${this.filteredRepairKitsToBePicked(true).length})`;
            },

            selectionsMade() {
                return (
                    Object.keys(this.searchValue).length &&
                    this.searchValue.warehouse &&
                    this.searchValue.type
                )
            }
        },

        methods: {
            ...mapActions([
                'displayToast',
                'loadTodaysRepairKits',
                'syncKitList'
            ]),

          ensureCurrentPageInBounds() {
              this.currentPage = 1;
          },
          loadMore() {
            this.itemsToLoad = this.filteredRepairKitsToBePicked(true).length; // Load the entire list
          },
          nextPage() {
            if (this.currentPage < this.totalPages) {
              this.currentPage++;
            }
          },
          previousPage() {
            if (this.currentPage > 1) {
              this.currentPage--;
            }
          },
            /** Return repair kits, including search results */
            filteredRepairKitsToBePicked(inProgresss = false) {

                // Don't display anything if no kit type is entered
                if (!this.selectionsMade) return [];

                // If it's a kit ID, don't display anything until more than 5 characters
                if (!this.searchValueIsAZone && this.filteredSearchValue.length < 3) return [];

                let filtered = this.repairKitsToBePicked;

                filtered = filtered.filter (
                    r => r.label_printed === inProgresss
                );

                if (this.selectionsMade) {
                    const warehouseId = this.searchValue.warehouse;
                    const kitType = this.searchValue.type.toLowerCase();

                    if (kitType === 'all') {
                        filtered = filtered.filter (
                            r => r.warehouse_id == warehouseId
                        );
                    } else {
                        filtered = filtered.filter(
                            r => r.warehouse_id == warehouseId && r.kit_type == kitType
                        );
                    }
                }

                if (this.filteredSearchValue) {
                    let filteredSearchValue = this.filteredSearchValue.toLowerCase();

                    if (filteredSearchValue !== 'all') {
                        filtered = filtered.filter (
                            r => r.id.includes(filteredSearchValue) || (r.picking_zone && r.picking_zone.toLowerCase().includes(filteredSearchValue))
                        );
                    }
                }

                return filtered.sort(function(a, b) {
                    if (a.priority < b.priority) {
                        return 1;
                    }
                    if (a.priority > b.priority) {
                        return -1;
                    }
                    return 0;
                });
            },

            syncList() {
                this.syncing = true;
                // this.syncKitList();

                let self = this;
                setTimeout(() =>{
                    self.syncing = false
                }, 4000);
            }
        }
    }
</script>

<style scoped>

</style>